import './index.less';

import Vue from "vue";
import Component from "vue-class-component";
import { AttachmentPage, ChildAttachmentGroupDtos, childAttachmentService, getAttachmentPageInput, IChildAttachment, SaveAttachmentPageDto } from '@/app/apiServices/childAttachment/public-api';
import helperService from '@/app/apiServices/helper/helperService';
import commonService from '@/app/apiServices/common/commonService';
import { BaseResultDto } from '@/app/apiServices/baseDto/resultDto';
import { Upload } from '@/app/components';
import storage from '@/core/utils/storage';
@Component({
  components: {
    Upload,
  },
})
export default class MaterialInformation extends Vue {
  attachmentPage: AttachmentPage[] = [];
  formModel = new SaveAttachmentPageDto();
  loading = false; //是否加载电子证照
  gradeCode = "";
  refId = "";
  childId = 0;

  //开关的change事件
  switchChange(val: boolean) {
    //加载电子证照
    if (val) {
      this.loading = true;

      // 证照库接口
      const { childId, gradeCode, enterGardenType }: any = storage.GET_UploadEvidenceChild();
      const { encryptId }: any = this.$store.getters.GET_CurrentUser;
      helperService
        .queryZZKCertData(childId, encryptId, gradeCode, enterGardenType)
        .then((res: any) => {
          if (res.data && res.data.success) {
            this.$dialog
              .alert({
                title: "提示",
                message:
                  "目前仅支持上海市公共管理与服务机构签发的部分证照，未加载到的证照可以拍照手动上传，无需重复加载。",
              })
              .then(() => {
                this.getAttachmentPage();
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            this.$toast("电子证照加载失败！");
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$toast("电子证照加载失败！");
        });
    }
  }

  //图片change事件
  fileChange(data: IChildAttachment[], fileType: number) {
    if (data.length > 0) {
      const find = this.attachmentPage.find((u) => u.fileType == fileType);
      if (find) {
        find.childAttachmentDtos = data;
        find.childAttachmentDtos.forEach((u) => {
          u.fileTypeName = find ? find.fileTypeName : "";
        });
      }
    }
  }

  //图片删除
  fileDelete(
    data: IChildAttachment,
    nowFiles: IChildAttachment[],
    fileType: number
  ) {
    if (data.id > 0) {
      this.formModel.delIds.push(data.id);
    }

    const find = this.attachmentPage.find((u) => u.fileType == fileType);
    if (find) {
      find.childAttachmentDtos = nowFiles;
    }
  }

  nextOnClick() {
    const fileDBInfoIsUndefineds = this.attachmentPage.filter(
      (a) =>
        (a.mustSupport || (!a.mustSupport && !(a.isCheck as boolean))) &&
        (a.childAttachmentDtos === null || a.childAttachmentDtos.length === 0)
    );

    if (fileDBInfoIsUndefineds.length > 0) {
      this.$toast(`请上传（${fileDBInfoIsUndefineds[0].fileTypeName}）！`);
      return;
    }

    this.attachmentPage.forEach((u) => {
      this.formModel.childAttachmentDtos.push(...u.childAttachmentDtos);
    });

    // 确定下一步
    const nextFun = () => {
      commonService
        .submitSupply(this.refId, true)
        .then((res: BaseResultDto) => {
          if (res.verifyResult.success) {
            this.saveAttachmentPage();
          } else {
            this.$dialog
              .alert({
                title: "提示",
                message: res.verifyResult.message,
              })
              .then(() => {
                this.$router.push("/record");
              });
          }
        });
    };

    this.$dialog
      .confirm({
        message: "确认提交验证材料，提交后无法修改?",
      })
      .then(() => {
        nextFun();
      })
      .catch(() => {
        // on cancel
      });
  }

  viewLoad() {
    const { gradeCode, refId, childId }: any = storage.GET_UploadEvidenceChild();
    this.gradeCode = gradeCode;
    this.refId = refId;
    this.childId = childId;

    commonService.submitSupply(this.refId, false).then((res: BaseResultDto) => {
      if (res.verifyResult.success) {
        this.getAttachmentPage();
      } else {
        this.$dialog
          .alert({
            title: "提示",
            message: res.verifyResult.message,
          })
          .then(() => {
            this.$router.push("/record");
          });
      }
    });
  }

  getAttachmentPage() {
    const { childId, enterGardenType, gradeCode }: any = storage.GET_UploadEvidenceChild();
    this.formModel.childId = childId;
    this.formModel.gradeCode = gradeCode;

    const input = new getAttachmentPageInput();
    input.childId = childId;
    input.enterType = enterGardenType;
    input.gradeCode = gradeCode;

    childAttachmentService
      .getAttachmentPage(input)
      .then((res: ChildAttachmentGroupDtos) => {
        this.attachmentPage = res.childAttachmentGroupDtos;
        this.attachmentPage.forEach((u) => {
          if (!u.hasFile) {
            u.childAttachmentDtos = [];
          }
          this.$set(u, "isCheck", "false");
        });
        this.$store.dispatch("ACTION_ChildAttachments", res);
      });
  }

  saveAttachmentPage() {
    this.formModel.isSupply = true;
    childAttachmentService.saveAttachmentPage(this.formModel).then((res: BaseResultDto) => {
      if (res.verifyResult.success) {
        this.$toast({
          message: "保存成功",
          position: "top",
        });
        this.$router.push("/record");
      }
      else {
        if (res.verifyResult.exceptionCode == "GOHOME") {
          this.$dialog.alert({
            message: res.verifyResult.message,
          }).then(() => {
            this.$router.push("/record");
          });
        }
        else {
          this.$toast({
            message: res.verifyResult.message,
            position: 'top'
          });
        }
      }
    });
  }

  back() {
    this.$router.go(-1);
  }

  mounted() {
    this.viewLoad();
  }
}